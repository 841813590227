<template>
  <AppBarLayout
    v-if="!replaceWithSearchBar"
    class="UsersAppBar UsersAppBar--app"
    action-buttons-class="ml-6"
    no-spacer
  >
    <template #header>
      {{ $t('user.ManageUsers') }}
    </template>

    <template #actions>
      <v-btn
        color="primary"
        outlined
        :to="createUserRoute"
        replace
        exact
      >
        <v-icon
          class="mr-2"
          v-text="'mdi-plus'"
        />
        {{ $t('user.User') }}
      </v-btn>

      <v-btn
        icon
        class="ml-8"
        @click="replaceWithSearchBar = true"
      >
        <v-icon v-text="'mdi-magnify'" />
      </v-btn>
    </template>
  </AppBarLayout>

  <AppBarLayout
    v-else
    no-spacer
    class="UsersAppBar UsersAppBar--search"
  >
    <v-text-field
      v-model="quickSearchModel"
      class="UsersAppBar__search-input rounded-0 elevation-0"
      solo
      autofocus
      :height="$vuetify.breakpoint.mdAndUp ? 64 : 56"
      hide-details
      label="Search user via email or user name"
      placeholder="Search user via email or user name"
    >
      <template #append>
        <v-btn
          icon
          @click="onSearchClear"
        >
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </template>
    </v-text-field>
  </AppBarLayout>
</template>

<script>
import * as R from 'ramda'

import { replaceRoute } from '../helpers'

import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'UsersAppBar',

  components: {
    AppBarLayout,
  },

  props: {
    searchQuery: { type: String, default: '' },
  },

  data() {
    const q = (this.searchQuery || '').trim()
    return {
      replaceWithSearchBar: !!q,
      quickSearchModel: q,
    }
  },

  computed: {
    newQuickSearchRoute() {
      const { quickSearchModel: q } = this

      const route = { name: 'Users' }
      if (q.trim()) route.query = { q }

      return route
    },

    createUserRoute() {
      const { $route } = this

      const query = $route.name === 'Users'
        ? R.omit(['userId'], $route.query)
        : {}
      query.action = 'create-user'

      return { name: 'Users', query }
    },
  },

  watch: {
    '$route.name'() { this.replaceWithSearchBar = false },

    searchQuery(q) {
      if (q !== this.quickSearchModel) this.quickSearchModel = q
    },

    newQuickSearchRoute(route) {
      this.$nextTick(() => replaceRoute(this.$router, route))
    },
  },

  methods: {
    onSearchClear() {
      this.quickSearchModel = ''
      this.replaceWithSearchBar = false
    },
  },
}
</script>

<style lang="sass" scoped>
.UsersAppBar
  &--search
    // box-shadow: 0 1px 2px rgba(0, 0, 0, .2) !important

    ::v-deep .v-toolbar__content
      padding-top: 4px
      padding-bottom: 0

  &__search-input
    ::v-deep
      .v-input__slot
        box-shadow: none !important
        padding-left: 0 !important
        padding-right: 0 !important
        height: 40px !important
      input, label
        font-size: 14px
      .v-input__append-inner
        margin-top: 0 !important
</style>
