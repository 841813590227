<template>
  <AppTable
    class="BotsTable"
    :headers="headers"
    :items="bots || []"
    :loading="bots == null"
    item-key="id"
    :sort-by.sync="sortModel"
    :sort-desc.sync="sortModelDesc"
    must-sort
    :row-to="getBotEditRoute"
    row-replace
  >
    <!--template #item="{ item: user }">
      <BotsTableRow
        :user="user"
        :invited-user="invitedUsers"
      />
    </template-->

    <template #item.userLogin="{ item: bot }">
      <UserAvatar
        :user="bot"
        icon="mdi-robot-outline"
        class="mr-4"
      />
      <div class="overflow-hidden">
        <v-list-item-title>
          {{ bot.userLogin }}
        </v-list-item-title>
        <v-list-item-subtitle class="text--secondary">
          {{ $t('user.botUser') }}
        </v-list-item-subtitle>
      </div>
    </template>

    <template #item.role="{ value: role }">
      <v-icon v-text="getRoleIcon(role)" />
    </template>

    <template #item._delete>
      <v-icon
        style="margin-bottom: 3px"
        :size="16"
        v-text="'$trash-can'"
      />
    </template>
  </AppTable>
</template>

<script>
import UserRole from '../store/orm/userRole'

import UserAvatar from './UserAvatar'

export default {
  name: 'BotsTable',

  components: {
    UserAvatar,
  },

  props: {
    bots: { type: Array, default: null },
  },

  data: () => ({
    sortModel: 'userLogin',
    sortModelDesc: false,

    mfaDialog: false,
    mfaDialogState: {
      userId: null,
      otpauthUrl: '',
      base32: '',
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('user.BotUser'),
          value: 'userLogin',
        },
        {
          text: this.$t('user.Role'),
          value: 'role',
          flex: '0 0 93px',
          align: 'center',
          // It is not strictly needed, just increases the possible click area
          // to match the whole cell (better UX only)
          tooltip: bot => this.getRoleDisplayName(bot.role),
        },
        {
          text: this.$t('user.Actions'),
          value: '_delete',
          flex: '0 0 120px',
          align: 'center',
          click: bot => this.delete(bot),
          tooltip: this.$t('user.DeleteBotUser'),
        },
      ]
    },
  },

  methods: {
    getBotEditRoute(bot) {
      const { $route } = this

      const query = $route.name === 'Users' ? { ...$route.query } : {}
      query.action = 'edit-bot'
      query.botId = bot.id

      return { name: 'Users', query }
    },

    async delete(bot) {
      const { $store } = this
      if (!(await $store.dispatch('confirm/openDialog', {
        title: this.$t('user.DeleteBotUserQ'),
        subtitle: this.$t('user.BotUserLogin', { userLogin: bot.userLogin }),
        consentLabel: this.$t('user.DeleteBot'),
        consentProps: { color: 'error', depressed: true },
      }))) return
      await $store.dispatch('botUser/delete', { botId: bot.id })
    },

    getRoleIcon(role) {
      const roleDetails = UserRole.find(role)
      return roleDetails && roleDetails.icon
    },

    getRoleDisplayName(role) {
      const roleDetails = UserRole.find(role)
      return roleDetails && roleDetails.displayName
    },
  },
}
</script>

<style lang="sass" scoped>
.BotsTable
  border-radius: 8px
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2)

  ::v-deep
    tr > th:first-child
      padding-left: 24px
</style>
