<template>
  <v-dialog
    content-class="BotDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="saving"
    max-width="466px"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
  >
    <v-card
      class="BotDialog__card"
      :max-width="466"
    >
      <form
        v-if="!innerBotId || bot"
        class="BotDialog__form text-truncate"
        @submit.prevent="validateAndSubmit()"
      >
        <h1
          v-if="!innerBotId"
          class="BotDialog__title display-3"
          v-text="$t('user.NewBot')"
        />

        <div
          v-else-if="bot"
          class="d-flex align-center text-truncate mb-6"
        >
          <UserAvatar
            :user="{ ...bot, ...form }"
            icon="mdi-robot-outline"
            size="xl"
          />
          <div class="text-truncate ml-4">
            <div class="display-2 text-truncate">
              {{ form.userLogin }}
            </div>
            <div
              class="text--secondary text-truncate"
              v-text="$t('user.botUser')"
            />
          </div>
        </div>

        <AppTextField
          key="login"
          v-model="form.userLogin"
          :label="$t('user.Login')"
          filled
          type="text"
          name="login"
          required
          autocomplete="nickname"
          :readonly="!!innerBotId"
          :error-messages="getErrors('userLogin')"
          :disabled="saving"
        />
        <AppSelect
          key="role"
          v-model="form.role"
          :label="$t('user.Role')"
          filled
          type="text"
          name="role"
          required
          autocomplete="off"
          :items="userRoles"
          item-value="name"
          item-text="displayName"
          :readonly="!!innerBotId"
          :error-messages="[]"
          :disabled="saving"
        >
          <template #item="{ item: role }">
            <v-icon
              class="mr-2"
              v-text="role.icon"
            />
            {{ role.displayName }}
          </template>
          <template #selection="{ item: role }">
            <v-icon
              class="mr-2"
              v-text="role.icon"
            />
            {{ role.displayName }}
          </template>
        </AppSelect>

        <v-btn
          v-if="innerBotId"
          :disabled="saving"
          :loading="saving"
          text
          plain
          color="primary"
          class="mt-4 ml-n4"
          @click="form.regenerateToken = true; $v.$touch(); validateAndSubmit()"
        >
          <v-icon
            class="mr-1"
            v-text="'mdi-refresh'"
          />
          {{ $t('user.RefreshToken') }}
        </v-btn>

        <section class="BotDialog__actions mt-10 pt-10">
          <v-btn
            v-if="!innerBotId && showRegularUserButton"
            :disabled="saving"
            :loading="saving"
            outlined
            color="primary"
            :to="createUserRoute"
            replace
            exact
          >
            {{ $t('user.CreateRegularUser') }}
          </v-btn>
          <v-btn
            v-else-if="innerBotId"
            outlined
            color="error"
            @click.prevent.stop="deleteBot"
          >
            {{ $t('user.Delete') }}
          </v-btn>

          <v-spacer />

          <v-btn
            :disabled="saving"
            :loading="saving"
            type="submit"
            depressed
            color="primary"
            @click="$v.$touch()"
          >
            {{ innerBotId ? $t('user.Save') : $t('user.Create') }}
          </v-btn>
          <v-btn
            :disabled="saving"
            outlined
            color="primary"
            class="ml-2"
            @click.prevent.stop="$emit('input', false)"
          >
            {{ $t('user.Cancel') }}
          </v-btn>
        </section>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import * as R from 'ramda'
import { required } from 'vuelidate/lib/validators'

import { reportError, replaceRoute } from '../helpers'

import UserRole from '../store/orm/userRole'

import UserAvatar from './UserAvatar'

const formValidation = {
  userLogin: { required },
}

export default {
  name: 'BotDialog',

  components: {
    UserAvatar,
  },

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: true }, // isOpen?
    botId: { type: String, default: null },
    showRegularUserButton: { type: Boolean, default: true },
  },

  data() {
    return {
      // preserves bot id during dialog fade out animation
      innerBotId: this.botId,
      form: null,
      saving: false,
    }
  },

  validations() {
    return {
      form: formValidation,
    }
  },

  computed: {
    validationErrors() {
      return {
        'userLogin-required': this.$t('user.validationErrors.UserLogin'),
      }
    },

    bot() {
      const { $store, innerBotId: botId } = this
      return botId ? $store.getters['botUser/get'](botId) : null
    },

    userRoles() {
      return UserRole.getOrderedQuery().all()
    },

    firstError() {
      const fields = Object.keys(formValidation)
      for (const field of fields) {
        const errors = this.getErrors(field)
        if (errors.length) return errors[0]
      }
      return null
    },

    createUserRoute() {
      const { $route } = this

      const query = $route.name === 'Users'
        ? R.omit(['userId', 'botId'], $route.query)
        : {}
      query.action = 'create-user'

      return { name: 'Users', query }
    },
  },

  watch: {
    value: {
      handler(isOpen) {
        this.$store.commit('defaultLayout/setPushAjaxLoaderTop', isOpen)
        if (isOpen) this.initForm()
      },
      immediate: true,
    },

    botId(botId) {
      if (this.value) this.innerBotId = botId
      else {
        setTimeout(() => {
          this.innerBotId = this.botId
        }, 300)
      }
    },

    bot: {
      handler: 'initForm',
      immediate: true,
    },
  },

  methods: {
    initForm() {
      const { bot } = this
      this.form = bot
        ? R.pipe(
          R.pick(['role', 'userLogin']),
          R.over(R.lensProp('userLogin'), R.defaultTo('')),
          R.over(R.lensProp('role'), R.defaultTo(UserRole.DEFAULT_ROLE)),
          R.assoc('regenerateToken', false),
        )(bot)
        : this.getDefaultForm()
      this.previewPassword = false
      this.$v.$reset()
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          this.validationErrors[`${field}-${k}`] ||
          `${this.$t('user.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async validateAndSubmit() {
      await this.$nextTick()

      const { $store, form, innerBotId: botId } = this

      this.$v.$touch() // highlight errors
      if (this.$v.$error) {
        return this.$store.commit('$snackbar/setMessage', {
          message: this.firstError || this.$t('user.fixErrorsM'),
        })
      }
      const saved = botId
        ? form.regenerateToken && $store.dispatch('botUser/regenerateToken', { botId })
        : $store.dispatch('botUser/register', {
          bot: {
            role: form.role,
            userLogin: form.userLogin,
          },
        })

      this.saving = true
      try {
        await saved
        await this.onSuccess()
      } catch (e) {
        reportError(e).catch(() => { /* pass  */ })
      } finally {
        this.saving = false
      }
    },

    async onSuccess() {
      this.$emit('input', false)
    },

    async deleteBot() {
      const { $store, bot: { id: botId } } = this

      this.$emit('input', false)

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('user.DeleteBotQ'),
        subtitle: this.$t('user.DeleteBotWarningM'),
        consentLabel: this.$t('user.DeleteBot'),
      })

      if (agreed) {
        this.saving = true
        try {
          await $store.dispatch('botUser/delete', { botId })
          await this.onSuccess()
        } finally {
          this.saving = false
        }
      } else {
        this.restorePreviousDialog(botId)
      }
    },

    restorePreviousDialog(botId) {
      const { $router, $route } = this

      const query = $route.name === 'Users' ? { ...$route.query } : {}
      query.action = 'edit-bot'
      query.botId = botId

      replaceRoute($router, { name: 'Users', query })
    },

    getDefaultForm() {
      return {
        userLogin: '',
        role: UserRole.DEFAULT_ROLE,
        regenerateToken: false,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.BotDialog
  &__card
    display: flex
    flex-direction: row

  &__form
    flex-grow: 1
    padding: 48px 48px 0

  &__title
    margin-bottom: 44px

  &__actions
    display: flex
    padding: 32px 32px 32px 48px
    margin: 0 -48px
</style>
