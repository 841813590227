<template>
  <div
    class="Users"
    :class="{ 'Users--empty-search': showEmptyState }"
  >
    <UsersAppBar :search-query="searchQuery" />

    <v-sheet
      v-show="showEmptyState"
      key="empty"
      color="#F1F1F6"
      class="Users__empty-state"
    >
      <v-img
        :src="require('@/assets/images/lost-in-space.png')"
        :width="459"
        :height="422"
        :transition="false"
        class="Users__empty-image"
      />

      <h1
        class="display-3"
        v-text="$t('user.NoMatchesFound')"
      />
    </v-sheet>

    <div v-show="!showEmptyState">
      <div
        class="Users__switch"
      >
        <AppSwitch
          :key="switchKey"
          :input-value="mfaRequired"
          class="ma-0 pa-0"
          :label="$t('user.RequireCode')"
          :disabled="savingSystemSettings"
          hide-details
          @change="setMfaRequired($event).finally(() => ++switchKey)"
        />
        <span
          class="text--secondary d-inline-block"
          v-text="mfaRequired ? $t('user.On') : $t('user.Off')"
        />
      </div>

      <UsersTable
        key="accepted-users"
        class="Users__table"
        :users="filteredUsers"
      />

      <BotsTable
        v-if="filteredBots && filteredBots.length"
        key="bots"
        class="Users__table"
        :bots="filteredBots"
      />
    </div>

    <BotDialog
      :value="showBotDialog"
      :bot-id="dialogBotId"
      @input="!$event && closeDialog()"
    />
    <UserDialog
      :value="showUserDialog"
      :user-id="dialogUserId"
      :password-change-required="changePassword"
      @input="!$event && closeDialog()"
    />
  </div>
</template>

<script>
import * as R from 'ramda'

import { replaceRoute } from '../helpers'

import UserRole from '../store/orm/userRole'

import BotDialog from '../components/BotDialog'
import BotsTable from '../components/BotsTable'
import UsersAppBar from '../components/UsersAppBar'
import UserDialog from '../components/UserDialog'
import UsersTable from '../components/UsersTable'

export default {
  name: 'Users',

  components: {
    BotDialog,
    BotsTable,
    UsersAppBar,
    UserDialog,
    UsersTable,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title('Users'),
    }
  },

  props: {
    showUserDialog: { type: Boolean, default: false },
    dialogUserId: { type: String, default: null },
    showBotDialog: { type: Boolean, default: false },
    dialogBotId: { type: String, default: null },
    searchQuery: { type: String, default: '' },
  },

  data() {
    return {
      switchKey: Number.MIN_SAFE_INTEGER,
      mfaRequired: null,
      savingSystemSettings: false,
    }
  },

  computed: {
    searchTokens() {
      return (this.searchQuery || '')
        .trim()
        .split(/\s+?/g)
        .map(s => s.trim().toLocaleLowerCase())
        .filter(Boolean)
    },

    users() {
      const users = this.$store.getters['user/list']
      return users && users
        .map(user => ({
          ...user,
          _searchBy: [
            user.firstName,
            user.lastName,
            user.userLogin,
            user.userEmail,
            user.id && String(user.id),
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        }))
    },
    filteredUsers() {
      const { users, searchTokens } = this
      if (!users) return null
      return searchTokens.length
        ? users.filter(user =>
          searchTokens.every(q =>
            user._searchBy.includes(q)))
        : users
    },

    bots() {
      const bots = this.$store.getters['botUser/list']
      return bots && bots
        .map(user => ({
          ...user,
          _searchBy: [
            user.userLogin,
            user.id && String(user.id),
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        }))
    },
    filteredBots() {
      const { bots, searchTokens } = this
      if (!bots) return null
      return searchTokens.length
        ? bots.filter(bot =>
          searchTokens.every(q =>
            bot._searchBy.includes(q)))
        : bots
    },

    systemSettings() {
      return this.$store.state.service.systemSettings
    },

    showEmptyState() {
      const { searchTokens, filteredUsers } = this
      return searchTokens.length > 0 &&
        filteredUsers != null &&
        filteredUsers.length === 0
    },

    changePassword() {
      return this.$route.query.changePassword === 'true'
    },
  },

  watch: {
    'systemSettings.mfaRequired': {
      handler(mfaRequired) { this.mfaRequired = mfaRequired ?? null },
      immediate: true,
    },
  },

  created() {
    this.fetchUsers()
    this.fetchBots()
    this.fetchSystemSettings()
    this.fetchRoles()
  },

  methods: {
    fetchUsers() {
      return this.$store.dispatch('user/getList')
    },

    fetchBots() {
      return this.$store.dispatch('botUser/getList')
    },

    fetchSystemSettings() {
      return this.$store.dispatch('service/getSystemSettings')
    },

    fetchRoles() {
      return UserRole.dispatch('$get')
    },

    closeDialog() {
      const { $router, $route } = this
      replaceRoute($router, {
        name: 'Users',
        query: R.omit(['action', 'userId', 'botId'], $route.query),
      })
    },

    setMfaRequired(mfaRequired) {
      const { $store, $router } = this
      return $store.dispatch(
        'service/setSystemSettings',
        { settings: { mfaRequired } },
      )
        .then(() =>
          mfaRequired &&
          !$store.getters['user/current']?.mfaConfirmed &&
          Promise.all([
            $store.commit('user/logOut'),
            $store.dispatch('reset'),
            $router.push({ name: 'Auth' }),
          ]))
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.Users
  $y-padding: 64px
  max-width: 1152px + $y-padding * 2
  margin: 0 auto
  padding: 88px $y-padding

  &--empty-search
    max-width: 100vw
    width: 100vw
    height: calc(100vh - 56px)
    margin: 0
    padding: 0
    background: #F1F1F6
    display: flex
    align-items: center
    justify-content: center

    @media #{map-get($display-breakpoints, 'md-and-up')}
      height: calc(100vh - 64px)

  &__empty-state
    text-align: center
    color: #C0BCCB
  &__empty-image
    margin-bottom: 33px

  &__switch
    margin-bottom: 28px
    display: flex
    align-items: center

  &__table
    & + &
      margin-top: 48px
</style>
