<template>
  <AppTable
    class="UsersTable"
    :headers="headers"
    :items="users || []"
    :loading="users == null"
    item-key="id"
    :sort-by.sync="sortModel"
    :sort-desc.sync="sortModelDesc"
    must-sort
    :row-to="getUserEditRoute"
    row-replace
    :row-background="(user) => user.state === USER_STATE.BLOCKED.value ? '#FFF1F1' : null"
  >
    <!--template #item="{ item: user }">
      <UsersTableRow
        :user="user"
        :invited-user="invitedUsers"
      />
    </template-->

    <template #item.userLogin="{ item: user }">
      <UserAvatar
        :user="user"
        class="mr-4"
      />
      <div class="overflow-hidden">
        <v-list-item-title>
          {{ user.firstName }}
          {{ user.lastName }}
        </v-list-item-title>
        <v-list-item-subtitle class="text--secondary">
          {{ user.userLogin }} {{ user.userEmail }}
        </v-list-item-subtitle>
      </div>
    </template>

    <template #item.role="{ value: role, item: user }">
      <v-menu>
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            @click.stop.prevent
            v-on="on"
          >
            <v-icon v-text="getRoleIcon(role)" />
            <v-btn
              :ref="`item-${user.id}-role-menu-btn`"
              icon
              x-small
            >
              <v-icon
                color="grey lighten-2"
                v-text="'mdi-menu-down'"
              />
            </v-btn>
          </div>
        </template>

        <v-list dense>
          <v-list-item
            v-for="roleItem in userRoles"
            :key="roleItem.name"
            style="padding: 0 14px"
            :input-value="roleItem.name === user.role"
            @click="setRole(user, roleItem.name)"
          >
            <v-icon
              class="mr-2"
              v-text="roleItem.icon"
            />
            <v-list-item-title>{{ $t(`user.${roleItem.displayName}`) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #item.state="{ item: user }">
      <v-menu>
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            @click.stop.prevent
            v-on="on"
          >
            <v-icon
              :size="24"
              :color="USER_STATE[user.state].iconColor"
              v-text="USER_STATE[user.state].icon"
            />
            <v-btn
              :ref="`item-${user.id}-state-menu-btn`"
              icon
              x-small
            >
              <v-icon
                color="grey lighten-2"
                v-text="'mdi-menu-down'"
              />
            </v-btn>
          </div>
        </template>

        <v-list dense>
          <v-list-item
            v-for="userState in userStateItems(user)"
            :key="userState.value"
            style="padding: 0 14px"
            :input-value="userState.value === user.state"
            @click="setState(user, userState.value)"
          >
            <v-icon
              class="mr-2"
              :color="userState.iconColor"
              v-text="userState.icon"
            />
            <v-list-item-title>{{ $t(userState.label) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #item.mfaConfirmed="{ item: user, value: mfaConfirmed, hover }">
      <v-btn
        :ref="`item-${user.id}-mfa-btn`"
        icon
        :disabled="!isMfaBtnEnabledFor(user)"
        :color="mfaConfirmed ? null : '#BDBDBD'"
        @click.prevent.stop="mfaConfirmed ? disableMfa(user) : enableMfa(user)"
      >
        <v-icon
          v-text="hover && isMfaBtnEnabledFor(user) && mfaConfirmed
            ? '$shield-off'
            : mfaConfirmed ? '$shield' : (hover && isMfaBtnEnabledFor(user) ? '$shield' : '$shield-off')"
        />
      </v-btn>
    </template>

    <template #append>
      <MfaSetupDialog
        v-model="mfaDialog"
        v-bind="mfaDialogState"
        @success="mfaDialog = false"
      />
    </template>
  </AppTable>
</template>

<script>
import { USER_STATE } from '../constants'
import { fmtDate } from '../helpers'
import { UserRoleValueSchema as USER_ROLE } from '../../gen'

import UserRole from '../store/orm/userRole'

import MfaSetupDialog from './MfaSetupDialog'
import UserAvatar from './UserAvatar'

export default {
  name: 'UsersTable',

  components: {
    MfaSetupDialog,
    UserAvatar,
  },

  props: {
    users: { type: Array, default: null },
  },

  data: () => ({
    USER_STATE,

    sortModel: 'userLogin',
    sortModelDesc: false,

    mfaDialog: false,
    mfaDialogState: {
      userId: null,
      otpauthUrl: '',
      base32: '',
    },
  }),

  computed: {
    currentUser() {
      return this.$store.getters['user/current']
    },

    userRoles() {
      return UserRole.getOrderedQuery().all()
    },

    headers() {
      return [
        {
          text: this.$t('user.User'),
          value: 'userLogin',
        },
        // {
        //   text: 'Email',
        //   value: 'userEmail',
        //   flex: '0 0 150px',
        // },
        {
          text: this.$t('user.TableRegistered'),
          value: 'registered',
          displayValue: user => user.registered && fmtDate(user.registered),
          flex: '0 0 132px',
        },
        {
          text: this.$t('user.TableRole'),
          value: 'role',
          flex: '0 0 93px',
          align: 'center',
          // It is not strictly needed, just increases the possible click area
          // to match the whole cell (better UX only)
          click: user => this.$refs[`item-${user.id}-role-menu-btn`].$el.click(),
          tooltip: user => this.getRoleDisplayName(user.role),
        },
        {
          text: this.$t('user.TableStatus'),
          value: 'state',
          flex: '0 0 106px',
          align: 'center',
          click: user => this.$refs[`item-${user.id}-state-menu-btn`].$el.click(),
          tooltip: user => this.$t(USER_STATE[user.state].label),
        },
        {
          text: this.$t('user.TableSecurityCode'),
          value: 'mfaConfirmed',
          flex: '0 0 150px',
          align: 'center',
          click: user => this.$refs[`item-${user.id}-mfa-btn`].$el.click(),
          tooltip: user => user.mfaConfirmed
            ? this.$t('user.ClickToDisableM')
            : this.isMfaBtnEnabledFor(user) ? this.$t('user.ClickToEnable') : this.$t('user.Disabled'),
        },
      ]
    },
  },

  methods: {
    getUserEditRoute(user) {
      const { $route } = this

      const query = $route.name === 'Users' ? { ...$route.query } : {}
      query.action = 'edit-user'
      query.userId = user.id

      return { name: 'Users', query }
    },

    async setRole(user, role) {
      const { $router, currentUser } = this
      if (currentUser?.id === user.id && currentUser?.isAdmin && role !== USER_ROLE.ADMIN) {
        if (!await this.$store.dispatch('confirm/openDialog', {
          title: this.$t('user.RevokeAdminQ'),
          subtitle: this.$t('user.LoseAdminPrivilegesM'),
        })) return
        await this.updateUser(user.id, { role })
        await $router.replace('/')
      }
      return this.updateUser(user.id, { role })
    },

    userStateItems(user) {
      if (user.state === USER_STATE.PENDING.value) {
        return [USER_STATE.PENDING, USER_STATE.ACTIVE, USER_STATE._REMOVE]
      }
      return [USER_STATE.ACTIVE, USER_STATE.BLOCKED]
    },

    async setState(user, state) {
      if (state === USER_STATE._REMOVE.value) {
        if (!await this.$store.dispatch('confirm/openDialog', {
          title: this.$t('user.DeclineUserQ'),
          subtitle: this.$t('user.UserWillBeBlockedM'),
        })) return
        return this.updateUser(user.id, { state: USER_STATE.BLOCKED.value })
      }
      if (state === USER_STATE.BLOCKED.value) {
        if (!await this.$store.dispatch('confirm/openDialog', {
          title: this.$t('user.BlockUserQ'),
          subtitle: this.$t('user.UserWillNotLoginM'),
        })) return
      }
      return this.updateUser(user.id, { state })
    },

    isMfaBtnEnabledFor(user) {
      const { currentUser } = this
      return (
        user.id === currentUser?.id ||
        (currentUser?.isAdmin && user.mfaConfirmed)
      )
    },

    async disableMfa(user) {
      const { $store } = this
      if (!await $store.dispatch('confirm/openDialog', {
        title: this.$t('user.DisablSecurityCodeQ'),
        subtitle:
          this.$t('user.UseIfAccessLostM'),
      })) return
      await $store.dispatch('user/disableMfa', { userId: user.id })
    },

    async enableMfa(user) {
      const { $store } = this
      const { otpauthUrl, base32 } =
        await $store.dispatch('user/enableMfa', { userId: user.id })
      this.mfaDialogState = {
        userId: user.id,
        otpauthUrl,
        base32,
      }
      this.mfaDialog = true
    },

    updateUser(userId, fields) {
      const { $store } = this
      return $store.dispatch('user/update', {
        user: {
          id: userId,
          ...fields,
        },
      })
        .then(this.reloadUser)
    },

    reloadUser(userId) {
      return this.$store.dispatch('user/getDetails', { userId })
    },

    getRoleIcon(role) {
      const roleDetails = UserRole.find(role)
      return roleDetails && roleDetails.icon
    },

    getRoleDisplayName(role) {
      const roleDetails = UserRole.find(role)
      return roleDetails && roleDetails.displayName
    },
  },
}
</script>

<style lang="sass" scoped>
.UsersTable
  border-radius: 8px
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2)

  ::v-deep
    tr > th:first-child
      padding-left: 24px
</style>
